// ==========================================================================
// Products list
// ==========================================================================

.products-list {
    list-style-type: none;
}

.products-list__item {
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;
    border-bottom: 1px solid color('greys','xlight');
    display: block;

    @include mq(md) {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}

.products-list__item-description {
    flex-basis: 30%;
    color: color('greys','dark');
    font-size: em(14,16);

    strong {
        display:block;
    }
}

.products-list__item-certifications {
    flex-basis: 50%;
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    @include mq(md) {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
    img {
        margin-right: $grid-gutter-width / 6;
        margin-bottom: $grid-gutter-width / 6;
        display: inline-block;
        @include mq(md) {
            margin-left: $grid-gutter-width / 6;
        }
    }
}

.products-list__item-codes {
    flex-basis: 20%;
    color: color('greys','dark');
    font-size: em(14,16);
    strong {
        display:block;
    }
}
