// ==========================================================================
// Tooltip
// ==========================================================================

.tooltip {
    display: block;
    background-color: color('greys','xdark');
    color: white;
    font-size: em(14, 16);
    min-width: 100%;
    width: 100%;
    padding: ($root-vgrid / 2) ($grid-gutter-width / 2);
    transition: $transition-normal ease;

    @include mq(sm) {
        position: absolute;
        bottom: $root-vgrid;
        left: 50%;
        transform: translate3d(-50%, -$root-vgrid / 2, 0);
        width: auto;
        min-width: 200px;
        opacity: 0;
        visibility: hidden;
    }

    @include mq(sm) {
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 7.5px 0 7.5px;
            border-color: #4c4c4c transparent transparent transparent;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }
    }
}
