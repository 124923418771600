@include nav-icon-svg('[gr-nav-trigger]');

.header {
    @extend %container;
    position: relative;
    padding-top: $root-vgrid / 2;
    padding-bottom: $root-vgrid / 2;
    z-index: 10;
    transition: background-color $transition-fast ease-in-out;

    &.is-opened {
        background-color: rgba(97, 94, 92, 0.9);
    }

    @include mq(md) {
        display: flex;
        padding-top: $root-vgrid * 1.7;
        padding-bottom: $root-vgrid * 1.5;

        > div {
            flex: 1;

            &:first-of-type {
                flex-basis: 170px;
                flex-grow: 0;

                @include mq(md) {
                    flex-basis: 150px;
                }

                @include mq(lg) {
                    flex-basis: 200px
                }

                svg {
                    @include mq(md) {
                        max-width: none;
                        width: 150px;
                        height: 39px;
                    }
                    @include mq(lg) {
                        width: 200px;
                        height: 39px;
                    }
                }
            }
        }
    }

    ul {
        list-style: none;

        li {
            display: block;

            @include mq(md) {
                display: inline-block;
            }
        }
    }

    a {
        color: $color-white;
        font-size: em(14px);
        letter-spacing: .5px;
    }
}


.header__nav-menu {
    @include mq(md) {
        display: flex;
        align-content: space-between;
        align-items: flex-start;

        > * {
            flex: 1;

            &:last-child {
                text-align: right;
                flex-grow: 0;
                flex-basis: 240px;
            }
        }
    }
}

.header__nav-icon {
    display: flex;

    img,
    svg {
        width: 100%;
        max-width: 140px;
        height: auto;
    }

    > a {
        flex: 1;

        &:first-of-type {
            align-self: flex-start;
        }

        &:last-of-type {
            text-align: right;
            padding-left: $grid-gutter-width / 2;
            flex-basis: 55px;
            flex-grow: 0;

            @include mq(md) {
                display: none;
            }
        }
    }
}


.header__nav-icon {
    [gr-nav-trigger].is-opened {
        rect {
            transition: transform 150ms ease-in-out 250ms;
        }

        g:nth-of-type(1),
        g:nth-of-type(3) {
            transition: transform 150ms ease-in-out;
        }

        g:nth-of-type(1) {
            transform: translateY(10px);

            rect {
                transform: rotate(45deg);
            }
        }

        g:nth-of-type(2) {
            rect {
                opacity: 0;
            }
        }

        g:nth-of-type(3) {
            transform: translateY(-10px);

            rect {
                transform: rotate(-45deg);
            }
        }
    }
}

.header__nav-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition-fast ease-in-out;

    &.is-opened {
        max-height: 1000px;
    }

    @include mq(md) {
        overflow: visible;
        max-height: none;
    }
}


.header__nav-primary {
    & {
        text-align: center;
        margin-top: $root-vgrid;
        position: relative;
        z-index: 1;

        @include mq(md) {
            display: flex;
            text-align: left;
            margin-top: 0;
        }
    }

    > li + li {

        @include mq(md) {
            margin-left: 1em;
        }
    }

    > li {
        padding-bottom: $root-vgrid / 2;
        margin-bottom: $root-vgrid / 1.5;

        a {
            transition: all $transition-fast ease-out;
        }

        &:nth-child(1) a:after,
        &:nth-child(4) a:after {
            background-color: color(brand, primary);
        }

        &:nth-child(2) a:after {
            background-color: color(brand, tertiary);
        }

        &:nth-child(3) a:after {
            background-color: color(brand, quaternary);
        }

        &:nth-child(1) a.is-active,
        &:nth-child(4) a.is-active {
            color: color(brand, primary);
        }

        &:nth-child(2) a.is-active {
            color: color(brand, tertiary);
        }

        &:nth-child(3) a.is-active {
            color: color(brand, quaternary);
        }

        @include mq(md) {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        @include mq(lg) {
            &:nth-child(1) a:hover,
            &:nth-child(4) a:hover {
                color: color(brand, primary);
            }

            &:nth-child(2) a:hover {
                color: color(brand, tertiary);
            }

            &:nth-child(3) a:hover {
                color: color(brand, quaternary);
            }
        }

        &.has-submenu {
            transition: all $transition-fast ease-in-out;
            position: relative;

            > a {
                cursor: default;
            }
        }

        &.has-submenu ul {
            transition: all $transition-fast ease-in-out;
            margin-top: $root-vgrid / 3;

            @include mq(md) {
                margin-top: 0;
                position: absolute;
                width: 100%;
                padding-bottom: 1em;
                transform: translate3d(0,-($root-vgrid / 4),0);

                a {
                    display: block;
                    padding: 0;
                    margin-top: 0.5em;
                }
            }
        }

        @include mq(md) {

            &.has-submenu:hover {
                background: rgba(97, 94, 92, .9);

                ul {
                    opacity: 1;
                    transform: translate3d(0,0,0);
                    background: rgba(97, 94, 92, .9);
                }

                > a:after {
                    transform: translateY(0px);
                }
            }
        }
    }

    > li:first-child {
        @include mq(md) {
            margin-left: 1em;
        }

        @include mq(lg) {
            margin-left: 3em;
        }
    }

    > li {
        a {
            @include mq(md) {
                padding: .5em;
            }

            @include mq(lg) {
                padding: 1em;
            }
        }

        > a {
            display: inline-block;
            padding-bottom: $root-vgrid / 6;
            color: $color-white;
            font-size: em(14px);
            text-transform: uppercase;
            letter-spacing: .5px;

            &:after {
                display: block;
                content: '';
                width: 100%;
                height: 4px;
                transform: translateY($root-vgrid / 6);
                background-color: $color-primary;
                transition: all $transition-fast ease-in-out;

            }

            @include mq(lg) {
                &:hover:after {
                    transform: translateY(0px);
                }
            }
        }
    }

    > li {

        ul {
            text-align: center;
            opacity: 1;

            @include mq(md) {
                opacity: 0;
                padding-top: .5em;
            }

            li {
                display: block;
            }
        }
    }
}

.header__nav-primary--left {
    text-align: center;

    a:first-child {
        color: $color-white;
        display: block;

        @include mq(md) {
            display: inline-block;
        }
    }
}

.header__nav-secondary {
    text-align: center;
    margin-top:  $root-vgrid;

    @include mq(md) {
        margin-top: 0;
        position: absolute;
        top: $root-vgrid / 2.3;
        right:  $grid-gutter-width / 1.5;
        text-align: left;

        a {
            padding: 0 .6em;
            &.is-active {
                color: color(brand,primary);
            }
            @include mq(lg) {
                transition: $transition-fast ease-out;
                &:hover {
                    color: color(brand,primary);
                }
            }
        }
    }
}



.header__search-container {
    position: relative;
    margin-bottom: $root-vgrid;

    > i {
        position: relative;
        display: none;
        cursor: pointer;
    }

    label {
        display: block;
        margin-bottom: $root-vgrid / 4;
    }

    input {
        height: 47px;
        color: $root-color;
        padding: 0 1em;
    }

    button {
        left: -4px;
        padding-bottom: 0.875rem;
        position: relative;
        @include mq(md) {
            left: -3px;
        }
    }

    @include mq(md) {

        > i {
            display: block;
            padding: 1em;
        }

        &.is-opened {

            z-index: 20;

            &:before {
                opacity: 1;

            }

            .header__search > div {
                opacity: 1;

                > * {
                    opacity: 1;
                    transition: opacity $transition-fast ease-in-out 100ms;
                }
            }
        }

        &:before {
            opacity: 0;
            content: '';
            display: block;
            width: 100%;
            height: 68px;
            background: rgba(97, 94, 92, .9);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            transition: opacity $transition-fast ease-in-out;
        }

        .header__search > div {
            opacity: 0;
            position: absolute;
            right: 0;
            bottom: -108px;
            display: flex;
            align-items: flex-start;
            width: 680px;
            padding: 1.5em;
            align-items: center;
            background: rgba(97, 94, 92, .9);

            transition: opacity $transition-fast ease-in-out;

            > * {
                opacity: 0;
                transition: opacity $transition-fast ease-in-out;
            }

            label {
                flex: 1;
                text-align: left;
                margin-bottom: 0;
            }

            input {
                flex-basis: 380px;
                flex-grow: 0;
                height: 47px;
            }

            button {
                flex-basis: 40px;
                flex-grow: 0;
                flex-shrink: 0;
                cursor: pointer;
                &:after {
                    font-family: 'GrootsIcons';
                }
            }
        }
    }

}
