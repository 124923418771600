// ==========================================================================
// Alpha listing
// ==========================================================================

.alpha-listing {
    list-style-type: none;
    margin-bottom: $root-vgrid;

    .alpha-listing__total {
        color: color('brand','primary');
        font-size: em(24,16);
        display: block;
        margin-bottom: $root-vgrid / 2;
        @include mq(lg) {
            display: inline-block;
            margin-right: $grid-gutter-width / 2;
            margin-bottom: 0;
        }
    }

    li {
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        margin-left: $grid-gutter-width / 8;
        margin-right: $grid-gutter-width / 8;
        margin-bottom: $root-vgrid / 4;
        @include mq(lg) {
            margin-bottom: 0;
        }
    }

    a {
        display: inline-block;
        position: relative;
        padding-left: $grid-gutter-width / 8;
        padding-right: $grid-gutter-width / 8;
        color: color('greys','dark');

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 6px;
            opacity: 0;
            background-color: color('brand', 'primary');
        }

        @include mq(lg) {
            transition: $transition-fast ease-out;
            &:after {
                transition: $transition-fast ease-out;
            }
            &:hover {
                color: color('brand', 'primary');
                transform: scale3d(1.5, 1.5, 1.5) translate3d(0, -2px, 0);
                &:after {
                    opacity: 1;
                    transform: scale3d(.5, .5, .5);
                }
            }
        }
    }

    span {
        color: color('greys','xlight');
        display: inline-block;
        padding-left: $grid-gutter-width / 4;
        padding-right: $grid-gutter-width / 4;
    }

}
