/* ==========================================================================
   Lazy-loading
   ========================================================================== */

[gr-lazyloader] {
    @include keep-ratio('16/9');

    //@extend %img-cover;

    position: relative;
    overflow: hidden;
    background-color: $lazy-loader-background-color;

    &:after {
        content: $gr-icon-loading;
        font-family: 'GrootsIcons';
        z-index: 2;
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        color: white;
        animation: spin $transition-slow linear infinite;
    }

    img {
        z-index: 3;
        filter: blur(20px);
        opacity: 0;
        transition: opacity $transition-fast map-get($easing, easeinsine), filter $transition-slow map-get($easing, easeinsine);

        &.is-lazyloaded {
            opacity: 1;
            filter: blur(0);
        }
    }
}
