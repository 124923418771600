// ==========================================================================
// Main
// ==========================================================================

// “One file to rule them all,
//  One file to find them,
//  One file to bring them all,
//  And in the Sass way merge them.”

// Groots
@import 'variables';
@import '../../ground/styles/ground';

@include typography();
@include form();
@include form-radio(20px, $color-primary);
@include form-checkbox(20px, $color-primary);
@include form-select();
@include form-input-file();
@include form-validation();
// Demo
//@import 'demo';

//Temporary, remove if headings or titles are done
.groots-item__font-secondary {
    font-family: $font-secondary;
}

@include grid('gr-grid', $grid-columns, $grid-gutter-width, $grid-container-width);

// Styleguide
@import './styleguide/logo';
@import './styleguide/buttons';
@import './styleguide/pagination';
@import './styleguide/breadcrumb';

// Components
@import './components/background';
@import './components/filters';
@import './components/team-member';
@import './components/brand';
@import './components/alpha-listing';
@import './components/alpha-brands';
@import './components/banner-side-by-side';
@import './components/become-client';
@import './components/category';
@import './components/cta';
@import './components/notice';
@import './components/filter-expend';
@import './components/products-list';
@import './components/tooltip';
@import './components/header';
@import './components/footer';
@import './components/_fixed-cta.scss';

body {
    background-color: white;
}

[class*="section__"] {
    @extend %container;
}

.typography{
    color: color('greys','dark');
    p {
        font-size: em(14px);
        line-height: 1.6;
    }
}

%section-title,
.section-title {
    @extend %h1;
    font-family: 'Libre Baskerville', serif;
    line-height: 1.25;
    font-size: 1.75em;
    @include mq(sm) {
        font-size: 2.25em;
    }
}

.button-container--centered {
    text-align: center;
}

.section__home-products {
    text-align: center;
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid * 2;
    .section-title {
        color: color('brand','secondary');
        text-align: center;
    }
    .btn--primary {
        margin-top: $root-vgrid;
    }
    @include mq(md) {
        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 66.6%;
        }
    }
}

.section__brands {
    position: relative;
    padding-top: $root-vgrid * 2;
    padding-bottom: $root-vgrid * 2;
}

.section__become-client-form {
    padding-bottom: $root-vgrid;
    .section-title {
        color: color('brand','secondary');
        margin-top: $root-vgrid * 2;
        margin-bottom: $root-vgrid;
    }
    .typography {
        @include mq(md) {
            max-width: 66.6%;
        }
    }
    .notice {
        margin-bottom: $root-vgrid;
        @include mq(md) {
            max-width: 66.6%;
        }
    }
    .button-container {
        text-align: center;
        @include mq(sm) {
            text-align: right;
        }
    }
    .h3 {
        color: color('brand','primary');
    }
}

.section__become-supplier-form {
    padding-bottom: $root-vgrid;
    .section-title {
        color: color('brand','secondary');
        margin-top: $root-vgrid * 2;
        margin-bottom: $root-vgrid;
    }
    .typography {
        @include mq(md) {
            max-width: 66.6%;
        }
    }
    .button-container {
        margin-top: $root-vgrid / 2;
    }
    .button-container--right {
        text-align: right;
    }
    .h3 {
        color: color('brand', 'primary');
    }
}

.section__404,
.section__privacy-policy,
.section__why-us {
    padding-bottom: $root-vgrid;
    .section-title {
        color: color('brand','secondary');
        margin-top: $root-vgrid * 2;
        margin-bottom: $root-vgrid;
    }
    .typography {
        @include mq(md) {
            max-width: 66.6%;
        }
    }
}

.section__privacy-policy {
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid / 2;

    h2 {
        @extend .h3;
        margin-bottom: 0;
        color: color('brand', 'primary');
    }

    p {
        margin-top: 0;
    }

    .typography {
        @include mq(md) {
            h2,
            ul,
            p {
                max-width: 66.6%;
            }
        }
    }
}

.section__our-engagement {
    padding-bottom: $root-vgrid * 2;

    .typography {
        @include mq(md) {
            max-width: 66.6%;
        }
    }

    .section-title {
        color: color('brand','secondary');
        margin-top: $root-vgrid * 2;
        margin-bottom: $root-vgrid;
    }

    .button-container {
        text-align: center;
    }

    .alpha-brands {
        padding-bottom: $root-vgrid;
        margin-top:  $root-vgrid * 2;

        & + .alpha-brands {
            margin-top: 0;
        }
    }

    .h3 {
        color: color('brand', 'primary');
    }
}

.section__our-team {
    padding-top: $root-vgrid * 2;
    padding-bottom: $root-vgrid * 2;

    & + .section__our-team {
        padding-top: 0;
    }

    [gr-grid~=row--block] {
        justify-content: center;
        align-items: center;
    }

    [gr-grid~=block] {
        width: 50%;
        @include mq(sm) {
            width: 33.3%;
        }
        @include mq(md) {
            width: 16.6%;
        }
    }

    .section-title {
        color: color('brand','secondary');
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }
    .button-container {
        text-align: center;
        margin-top: $root-vgrid;
    }
    .typography {
        text-align: center;
        p {
            @include mq(md) {
                max-width: 66.6%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.section__trends {
    padding-top: $root-vgrid * 2;
    padding-bottom: $root-vgrid * 1.5;
    .typography {
        @include mq(md) {
            max-width: 66.6%;
        }
    }
    .alpha-brands {
        padding-bottom: $root-vgrid;
    }
    .alpha-listing__total {
        @include mq(lg) {
            width: 216px;
        }
    }
}

.section__contact-us {
    padding-bottom: $root-vgrid;
    .button-container {
        margin-top: $root-vgrid / 2;
        text-align: center;
        @include mq(sm) {
            text-align: right;
        }
    }
}

.section__search-results {
    padding-bottom: $root-vgrid;

    .section-title {
        color: color('brand','secondary');
        margin-top: $root-vgrid * 2;
        margin-bottom: $root-vgrid;
    }

    .alpha-brands {
        padding-top: 0;
        ul {
            margin-bottom: $root-vgrid;
        }
    }

    .h3 {
        color: color('brand', 'primary');
    }
}

[gr-grid~=row] {
    aside[gr-grid~='sm-1'] {
        padding-bottom: 0;
        flex-basis: 12.5%;
        @include mq(sm) {
            padding-bottom: $root-vgrid * 2;
            max-width: 300px;
        }
        @include mq(md) {
            flex-basis: 1;
        }
    }
    .btn--primary {
        margin-top: $root-vgrid
    }
    .cta {
        display: none;
        @include mq(sm) {
            display: block;
            margin-top: $root-vgrid;
        }
    }
}

.form {
    ul.errors {
        list-style: none;
        color: $color-error;
        margin-top: 1rem;
    }
}

.form-item  {
    &.is-hidden {
        display: none;
    }
    input {
        transition: $transition-fast ease-out;
    }
}

%banner-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;

    & > img,
    picture > img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    // For IE9, IE10 and IE11
    objectfit {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;

        img {
            opacity: 0;
        }
    }
}

.select-wrapper .select-options.is-active  {
    background-color: color('greys','xlight');
}

.select-wrapper {
    .select-styled {
        &:after {
            line-height: 44px;
        }
    }
}

.select-hidden {
    width: 100%;
}

@media print {
    .background,
    .background__breadcrumb,
    .background__footer-top,
    .section__footer,
    aside[gr-grid~='sm-1'],
    .pagination,
    .filters-wrapper,
    .btn--primary {
        display: none;
    }
    .brand-description {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        img {
            display: none;
        }
    }
}

.products {
    position: relative;
    &:before {
        content: '';
        transition: $transition-fast ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        background-color: white;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $transition-fast ease;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        background-image: url('../img/loader.svg');
        background-repeat: no-repeat;
        background-position: center $root-vgrid;
    }
    &.is-loading {
        &:before {
            z-index:666;
            opacity: 1;
            visibility: visible;
        }
        &:after {
            z-index:667;
            opacity: 1;
            visibility: visible;
        }
    }
}

.file-upload-wrapper {
    width: 100%;
}

.certification {
    z-index: 666;
    display: inline-block;

    .expander__content {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        display: block;
        height: 0;
        overflow: hidden;
        transition: height 240ms linear;
        @include mq(sm) {
            overflow: visible;
        }
    }

    @include mq(sm) {
        position: relative;
        &:hover {
            .tooltip {
                transform: translate3d(-50%, -$root-vgrid, 0);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.sub-category > img {
    margin-top: $root-vgrid * 2;
    width: 146px;
}

.white-bg {
    background-color: white;
}

// Components: Accordion-Tabs
// --------------------------------------------------------------------------

// ==========================================================================
// Accordion Tabs
// ==========================================================================

@mixin accordion-tabs($accordion-tabs-class: '.gr-tabs', $accordion-tabs-breakpoint: sm) {

    // Baseline styles
    // ==========================================================================

    #{$accordion-tabs-class} {

        &[gr-acctab='acc'] {
            .tab {
                list-style: none;
            }

            .tab-link {
                display: block;

                &.is-active + .is-open {
                    display: block;
                }
            }

            .tab-content {
                display: none;
                width: 100%;
            }
        }

        &[gr-acctab='tab'] {
            overflow: hidden;

            .tab {
                display: inline;
            }
            .tab-link {
                display: inline-block;

                &.is-active + .is-open {
                    display: block;
                }
            }
            .tab-content {
                display: none;
                width: 100%;
                float: left;
            }
        }

        &[gr-acctab]:not([gr-acctab='tab']):not([gr-acctab='acc']) {
            @if $accordion-tabs-breakpoint and $accordion-tabs-breakpoint!=0 {
                .tab {
                    list-style: none;
                }

                .tab-link {
                    display: block;

                    &.is-active + .is-open {
                        display: block;
                    }
                }

                .tab-content {
                    display: none;
                    width: 100%;
                }

                @include mq($accordion-tabs-breakpoint) {

                    .tab {
                        display: inline;
                    }
                    .tab-link {
                        display: inline-block;
                    }
                    .tab-content {
                        float: left;
                    }
                }
            }
        }
    }
}



@include accordion-tabs('.tabs');

.tabs {
    .alpha-brands {
        border: 0;
    }


    .tab-link {

        font-size: 1.5rem;

        padding: .5em ($root-hgrid / 2);
        background: color(greys, xlight);

        &.is-active {
            background: $color-primary;
            color: white;

            & + .is-open {
                display: flex;
            }
        }

        position: relative;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        font-size: em(14, 16);
        font-weight: 700;
        padding: ($root-vgrid / 2) ($grid-gutter-width / 2);
        //color: $snow-white;
        //background-color: color(brand,secondary);
        @include mq(lg) {
            transition: $transition-fast ease-out;
            &:hover {
                //background-color: darken($color-secondary, 10%);
            }
        }
    }

    .tab-content {
        font-size: 1rem;
        text-align: left;
        padding: $root-vgrid 0;
        //border: 1px solid color(greys, xlight);
    }
}

.u-flex-btn-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0 !important;
    
    @include mq(sm) {
        align-items: flex-end;

        .button-container {
            margin-top: 0;
        }
        
        [class*='btn'] {
            margin-top: 0;
        }
    }
}