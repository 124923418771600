.cta {
    display: block;
    max-width: 280px;
    margin: 0 auto;
    position: relative;
    padding: 1.5em;
    overflow: hidden;
    color: $color-white;

    @include mq(sm) {
        width: 100%;
        margin: 0;
    }

    .cta__title {
        @extend %h4;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: .5px;
        margin: 0;
    }

    p {
        display: inline-block;
        color: white;
    }

    .cta__cover {
        @extend %banner-cover;
    }

    .btn--secondary {
        width: 100%;
    }

    @include mq(lg) {

        &:hover .btn--secondary:after {
            opacity: 1;
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
        }
    }
}
