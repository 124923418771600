
.fixed-cta {
    width: 100%;
    max-width: 320px;
    position: fixed;
    z-index: 999;
    bottom: -1px;
    right: 50%;
    transform: translateX(50%);

    @include mq(sm) {
        right: 5vh;
        transform: none;
    }

    @include mq(lg) {
        right: 20vh;
    }

    &.is-opened {
        .fixed-cta__header {

            .fixed-cta__title:not(.fixed-cta__title--closed) {
                display:block;
            }

            .fixed-cta__title--closed {
                display:none;
            }

            i {
                transform: scale(1, -1);
            }
        }

        .fixed-cta__wrapper {
            max-height: 250px;
        }
    }

    .fixed-cta__wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-normal ease-in-out;
        //background-image: url('/dist/img/webp/textures/wooden-texture.jpg');
        background: $color-quaternary;
        //color: color(greys, dark);
    }

    .fixed-cta__header {
        cursor: pointer;
        padding: .3em 1em;
        background-color: color(brand, secondary);
        color: $white;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .fixed-cta__title:not(.fixed-cta__title--closed) {
            display:none;
        }

        i {
            flex: 1;
            text-align: right;
            font-size: em(24px);
            transition: transform $transition-normal ease-in-out;
        }
    }

    .fixed-cta__inner {
        margin-top: .7em;
        padding: 1em;
        display: flex;
        flex-wrap: wrap;
        //color: $white;

        .typography {
            color: currentColor;
            font-size: em(14px);
            letter-spacing: 1px;
        }

        > * {
            flex: 1;
        }

        i {
            flex-grow: 0;
            flex-basis: 55px;
            margin-right: 15px;

            path {
                fill: currentColor;
            }
        }
    }

    [class*="btn--"] {
        width: 100%;
        flex-basis: 100%;
        margin-top: $root-vgrid;
    }
}
