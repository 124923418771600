.section__footer {
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;
    color: color(greys, dark);

    [gr-grid=row] {
        > [gr-grid] {

            &:not(:last-of-type) {
                margin-bottom: $root-vgrid;
            }

            @include mq(sm) {
                &:first-child {
                    flex-basis: 100%;
                }
            }

            @include mq(md) {
                &:first-child {
                    flex-basis: 0;
                }
            }
        }
    }

    ul {
        list-style: none;

        li:first-child {
            @extend %footer-title;
        }

        li {
            font-size: em(14px);
        }
    }

    a:not([class*="btn--"]) {
        color: currentColor;
        display: block;
        @include mq(lg) {
            transition: $transition-fast ease-out;
            &:hover {
                color: color('brand','primary');
            }
        }
    }

    svg {
        margin-bottom: $root-vgrid / 2;
    }
    svg path {
        fill: color(greys, dark);
    }

    address {
        max-width: 200px;
        font-style: normal;
        margin-bottom: $root-vgrid / 2;
    }
}


.footer__title {
    @extend %footer-title;
}

%footer-title {
    text-transform: uppercase;
    font-size: remy(16px);
    margin-bottom: $root-vgrid / 4;
    font-weight: 400;
}

.background__footer-top {
    position:relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 1em $grid-gutter-width /2;
    clear: both;

    @include mq(md) {
        flex-direction: row;
        padding-top: $root-vgrid * 2;
        padding-bottom: $root-vgrid * 2;
        &:hover {
            .background__footer-top-cover:after {
                opacity: 0.3;
            }
        }
    }

    a:nth-child(2):after {
        background-color: color(brand, primary);
    }

    a:nth-child(3):after {
        background-color: color(brand, secondary);
    }

    a:nth-child(4):after {
        background-color: color(brand, quaternary);
    }

    @include mq(lg) {
        a:nth-child(2):hover {
            color: color(brand, primary);
        }

        a:nth-child(3):hover {
            color: color(brand, secondary);
        }

        a:nth-child(4):hover {
            color: color(brand, quaternary);
        }
    }

    a {
        padding-bottom: .5em;
        color: $color-white;
        text-transform: uppercase;
        align-self: center;
        transition: all $transition-fast ease-out;
        padding: 1em 0;

        @include mq(sm) {
            margin-left: 2em;
            margin-right: 2em;
        }

        @include mq(md) {
            min-width: 0;
        }

        @include mq(lg) {
            &:hover:after {
                transform: translateY(0px);
            }
        }

        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 4px;
            transform: translateY($root-vgrid / 6);
            background-color: $color-primary;
            transition: all $transition-fast ease-in-out;

        }

    }

}

.background__footer-top-cover {
    @extend %banner-cover;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $darth-vader;
        opacity: 0;
        z-index: 0;
        transition: $transition-fast ease;
    }
}
