// ==========================================================================
// Alpha brands
// ==========================================================================

.alpha-brands {
    border-top: 1px solid color('greys','xlight');
    padding-top: $root-vgrid;
    margin-bottom: $root-vgrid;

    @include mq(sm) {
        margin-bottom: 0;
    }

    ul {
        list-style-type: none;
        @include mq(sm) {
            margin-bottom: $root-vgrid;
        }
    }

    a {
        color: color('greys','dark');
        font-size: em(14,16);
        height: 100%;
        @include mq(lg) {
            transition: $transition-fast ease-out;
            &:hover {
                color: color('brand','primary');
            }
        }
    }

    @include mq(md) {
        [gr-grid~='sm-3'] {
            flex-grow: 0;
            flex-basis: 21.25%;
        }
    }
}

.alpha-brands__letter[gr-grid~='sm-1'] {
    flex-grow: 0;
    flex-basis: 15%;
    span {
        text-transform: uppercase;
        font-size: em(24,16);
        color: color('brand','primary');
    }
}
