// ==========================================================================
// Logo
// ==========================================================================

.logo {
    display: inline-block;
}

.logo--white {
    svg {
        * {
            fill: $snow-white;
        }
    }
}

.logo--grey {
    svg {
        * {
            fill: color('greys', 'dark');
        }
    }
}
