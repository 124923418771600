// ==========================================================================
// Maps
// ==========================================================================

// Sass to Js
// -------------

@import '../../../../node_modules/sass-to-js/sass/sass-to-js';

.bp-map:before {
    content: sassToJs($grid-breakpoints); // stylelint-disable-line
    display: none;
}

// Social Medias icons/color mapping
// -------------

$socials: (
    twitter: (
        char: $gr-icon-twitter,
        color: #55acee
    ),
    google: (
        char: $gr-icon-googleplus,
        color: #dd4b39
    ),
    facebook: (
        char: $gr-icon-facebook,
        color: #3b5998
    ),
    pinterest: (
        char: $gr-icon-pinterest,
        color: #bd081c
    ),
    instagram: (
        char: $gr-icon-instagram,
        color: #3f729b
    ),
    linkedin: (
        char: $gr-icon-linkedin,
        color: #0077b5
    ),
    mailto: (
        char: $gr-icon-mail,
        color: #f00
    ),
    rss: (
        char: $gr-icon-rss,
        color: #f60
    ),
    vimeo: (
        char: $gr-icon-vimeo,
        color: #f60
    ),
    youtube: (
        char: $gr-icon-youtube,
        color: #f60
    )
);

// Custom easing mapping
// -------------

$easing: (
    easeinsine:     cubic-bezier(.47, 0, .745, .715),
    easeoutsine:    cubic-bezier(.39, .575, .565, 1),
    easeinoutsine:  cubic-bezier(.39, .575, .565, 1),
    //
    easeinquad:     cubic-bezier(.55, .085, .68, .53),
    easeoutquad:    cubic-bezier(.25, .46, .45, .94),
    easeinoutquad:  cubic-bezier(.25, .46, .45, .94),
    //
    easeincubic:    cubic-bezier(.55, .055, .675, .19),
    easeoutcubic:   cubic-bezier(.215, .61, .355, 1),
    easeinoutcubic: cubic-bezier(.215, .61, .355, 1),
    //
    easeinquart:    cubic-bezier(.895, .03, .685, .22),
    easeoutquart:   cubic-bezier(.165, .84, .44, 1),
    easeinoutquart: cubic-bezier(.165, .84, .44, 1),
    //
    easeinquint:    cubic-bezier(.755, .05, .855, .06),
    easeoutquint:   cubic-bezier(.23, 1, .32, 1),
    easeinoutquint: cubic-bezier(.23, 1, .32, 1),
    //
    easeinexpo:     cubic-bezier(.95, .05, .795, .035),
    easeoutexpo:    cubic-bezier(.19, 1, .22, 1),
    easeinoutexpo:  cubic-bezier(.19, 1, .22, 1),
    //
    easeincirc:     cubic-bezier(.6, .04, .98, .335),
    easeoutcirc:    cubic-bezier(.075, .82, .165, 1),
    easeinoutcirc:  cubic-bezier(.075, .82, .165, 1),
    //
    easeinback:     cubic-bezier(.6, -.28, .735, .045),
    easeoutback:    cubic-bezier(.175, .885, .32, 1.275),
    easeinoutback:  cubic-bezier(.68, -.55, .265, 1.55)
);
