// ==========================================================================
// Buttons
// ==========================================================================

%btn {
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: em(14, 16);
    font-weight: 700;
    padding: ($root-vgrid / 2) ($grid-gutter-width / 2);
    color: $snow-white;
    cursor: pointer;
    @include mq(lg) {
        &:after {
            content: attr(data-content);
            text-transform: uppercase;
            padding: ($root-vgrid / 2) ($grid-gutter-width / 2);
            color: $snow-white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: scale3d(1.1, 1.1, 1.1) translate3d(0, ($root-vgrid / 4), 0);
            transition: $transition-normal ease;
        }
        &:hover:after {
            opacity: 1;
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
        }
    }
}

.btn--primary {
    @extend %btn;
    background-color: color('brand', 'primary');
    &:after {
        background-color: darken($color-primary, 10%);
        transform: translate3d(0, 0, 0);
        transition: $transition-normal ease;
    }
}

.btn--secondary {
    @extend %btn;
    background-color: color('brand', 'secondary');
    &:after {
        background-color: darken($color-secondary, 10%);
        transform: translate3d(0, 0, 0);
        transition: $transition-normal ease;
    }
}

.btn--full-width {
    display: block;
    width: 100%;
}
