.filter-expend {
    position: relative;
    border-bottom: 1px solid color(greys, xlight);



    &.is-opened {

        &:after {
            content: '';
            width: calc(100% - 25px);
            height: 33px;
            position: absolute;
            left: 0;
            bottom: 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+98&0+0,1+35 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 98%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,1) 98%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,1) 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        }


        .filter-expend__title {
            color: color(brand, primary);
        }

        .filter-expend__container {
            overflow: auto;
            max-height: 190px;
            margin-bottom: $root-vgrid;
        }
    }

    .filter-expend__title {
        display: block;
        padding: $root-vgrid/3 0;
        text-transform: uppercase;
        font-size: em(14, 16);
        color: color(greys, xdark);
        cursor: pointer;
        transition: color $transition-fast ease-in-out;
    }

    .filter-expend__container {
        position: relative;
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-fast ease-in-out;



        .form-item {
            margin-bottom: $root-vgrid / 4;
        }

        input:checked + label {
            color: color(brand, primary);
            transition: color $transition-fast ease-in-out;
        }
    }
}

.filter-expend--absolute {
    @extend .filter-expend;
    border-bottom: 1px solid color('greys','light');

    &:after {
        display: none;
    }

    &:before {
        font-family: 'GrootsIcons';
        content: "\f107";
        font-size: 1.5em;
        color: #000;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        width: 2.75rem;
        height: 2.75rem;
        line-height: 44px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        transition: transform 240ms ease-in-out;
    }

    &.is-opened {
        .filter-expend__container {

            border-bottom: 1px solid color('brand','primary');
        }
        &:before {
            transform: rotate(-180deg);
        }
    }

    .filter-expend__title {
        text-transform: none;
        color: color('greys','light');
    }

    .filter-expend__container {
        top: 44px;
        left: 0;
        position: absolute;
        z-index: 666;
        background-color: color('greys','xlight');
        > .checkbox-wrapper {
            margin: $root-vgrid / 4 $grid-gutter-width / 2;
        }
    }
}

.filters-wrapper {
    padding-top: $root-vgrid / 2;
    @include mq(sm) {
        padding-top: $root-vgrid;
    }
}

.filters-wrapper.is-opened {
    .filters-wrapper__container {
        display: block;
    }

    .filters-wrapper__title:before {
        transform: rotate(-180deg);
    }
}

.filters-wrapper__title {
    position: relative;
    text-transform: uppercase;
    color: color('brand','primary');

    &:before {
        font-family: 'GrootsIcons';
        content: "\f107";
        font-size: 1.5em;
        color: #000;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        transition: transform 240ms ease-in-out;
        @include mq(sm) {
            display: none;
        }
    }
}

.filters-wrapper__container {
    display: none;
    @include mq(sm) {
        display: block;
    }
    .filter-expend {
        padding-left: $grid-gutter-width / 2;
    }
}

.checkbox-wrapper {
    padding-left: $grid-gutter-width / 2;
    label {
        font-size: em(14,16);
    }
}
