// ==========================================================================
// Breadcrumb
// ==========================================================================

.breadcrumb__item {
    display: inline-block;
    font-size: em(14, 16);
    font-family: $font-primary;
    padding-bottom: $root-vgrid / 4;

    & + & {
        position: relative;
        margin-left: $grid-gutter-width / 4;
        padding-left: $grid-gutter-width * .75;
        &:before {
            content: "\f105";
            font-family: 'GrootsIcons';
            font-size: em(30, 16);
            color: color('brand', 'primary');
            position: absolute;
            left: 2px;
            top: -8px;
        }
    }

    a {
        color: $snow-white;
        @include mq(lg) {
            transition: $transition-fast ease-out;
            &:hover {
                color: color('brand', 'primary');
            }
        }
    }
}
