// ==========================================================================
// Mixin Clearfix
// ==========================================================================

@mixin clearfix {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}
