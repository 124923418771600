// ==========================================================================
// Variables
// ==========================================================================


// Layout: Grid breakpoints & parameters
// --------------------------------------------------------------------------

$grid-breakpoints: (
    //root: 0,
    xs:                            '30em', // 480px
    sm:                            '48em', // 768px
    md:                            '64em', // 1024px
    lg:                            '80em'  // 1280px
);

$grid-columns:                     12;
$grid-gutter-width:                30px;
$grid-container-width:             1280px;


// Fonts
// --------------------------------------------------------------------------

$font-path:                        '../fonts/' !default;

$font-primary:                     'Montserrat' !default;
$font-secondary:                   'Libre Baskerville' !default;


// Colors (variables & $colors map)
// --------------------------------------------------------------------------

$color-globalia:                   #1b9cd7;

$colors: (
    root: (
        black:                     #000,
        white:                     #fff,
    ),
    brand: (
        primary:                   #d35525,
        secondary:                 #74b3a0,
        tertiary:                  #a5d9c9,
        quaternary:                #edf4d3,
        quinary:                   #332825
    ),
    greys: (
        xdark:                     #4c4c4c,
        dark:                      #615e5c,
        base:                      #7f7f7f,
        light:                     #b2b2b2,
        xlight:                    #e1ddda
    ),
    validation: (
        error:                     #f00,
        success:                   #40b01a,
    )
);

$color-white:                      map-get(map-get($colors, root), white);
$color-black:                      map-get(map-get($colors, root), black);
$color-primary:                    map-get(map-get($colors, brand), primary);
$color-secondary:                  map-get(map-get($colors, brand), secondary);
$color-tertiary:                   map-get(map-get($colors, brand), tertiary);
$color-quaternary:                 map-get(map-get($colors, brand), quaternary);
$color-error:                      map-get(map-get($colors, validation), error);
$color-success:                    map-get(map-get($colors, validation), success);


// Globals
// --------------------------------------------------------------------------

$root-color:                       $color-black !default;
$root-cursor:                      default !default;

$root-font-family:                 $font-primary !default;
$root-font-size:                   16px !default;
$root-line-height:                 1.5 !default;

$root-text-rendering:              optimizeLegibility !default;

$root-margin:                      1em !default;
$root-padding:                     1em !default;

$root-border:                      1px solid currentColor !default;
$root-radius:                      0 !default;


//  Navigation
// --------------------------------------------------------------------------

$nav-height:                       4rem !default;
$nav-mobile-panel-width:           20rem !default;


//  Headings
// --------------------------------------------------------------------------

$heading-max:                      2.25em !default; // h1 font-size as reference to calculate the modular-scale and typographic headings font-size


//  Form
// --------------------------------------------------------------------------

$form-element-background-color:    transparent !default;
$form-element-height:              2.75rem !default;
$form-element-radius:              $root-radius !default;
$form-element-border-style:        solid;
$form-element-border-color:        lighten($color-black, 65%) !default;
$form-element-border-width:        0 0 1px !default;
$form-element-focus-color:         $color-primary !default;


//  Misc.
// --------------------------------------------------------------------------

$media-element-vertical-align:     middle !default;

$selection-background-color:       lighten($color-globalia, 40%) !default;
$selection-color:                  $color-white !default;
$selection-text-shadow:            none !default;

$table-border-collapse:            collapse !default;
$table-border-spacing:             0 !default;


//  Transitions
// --------------------------------------------------------------------------

$transition-fast:                  240ms;
$transition-normal:                480ms;
$transition-slow:                  720ms;


//  Components
// --------------------------------------------------------------------------

$z-indexes: (
    overlay:                       30,
    nav:                           40,
    modal:                         50
);


// Lazy-loader
// --------------------------------------------------------------------------

$lazy-loader-background-color:     lighten($color-black, 65%) !default;
