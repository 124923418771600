// ==========================================================================
// Brand
// ==========================================================================

.brand {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 3px solid color('greys','xlight');
    @include mq(lg) {
        &:after {
            content: '';
            border: 3px solid color('brand','primary');
            position: absolute;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            opacity: 0;
            transform: scale3d(1.1, 1.1, 1.1) translate3d(0, ($root-vgrid / 4), 0);
            transition: $transition-fast ease-out;
        }
        &:hover:after {
            opacity: 1;
            transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
        }
    }
}

.brand__logo {
    display: flex;
    flex-direction: columns;
    min-height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: color('greys','dark');
    img {
        max-width: 160px;
    }
}


.brand-cat-title {
    @extend .section-title;
    margin-bottom: 0;
    color: color('brand','secondary');
}

.brand-subcat-title {
    @extend .h3;
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid / 4;
    color: color('brand','primary');
}

.brand-description {
    position: relative;
    padding: $root-vgrid $grid-gutter-width $root-vgrid;
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid;
    background-color: color('brand','quaternary');
    @include mq(sm) {
        padding: $root-vgrid $grid-gutter-width $root-vgrid ($grid-gutter-width * 2 + 146px);
    }

    img {
        display: block;
        margin: 0 auto;
        max-width: 146px;
        @include mq(sm) {
            position: absolute;
            top:  $root-vgrid;
            left: $grid-gutter-width;
        }
    }

    .section-title {
        @include mq(sm) {
            margin-top: 0;
        }
        color: color('brand','secondary');
    }

}

.brand-description--no-logo {
    @include mq(sm) {
        padding: $root-vgrid $grid-gutter-width $root-vgrid;
    }
}
