.section__banner-side-by-side {
    max-width: none;
    background-color: color(brand, quaternary);

    [gr-grid=row] [gr-grid] {
        position: relative;
    }

    .banner-side-by-side__title {
        @extend %section-title;
        margin-top: 0;
        margin-bottom: $root-vgrid / 2;
        color: color(brand, secondary);
    }

    .banner-side-by-side__content {
        padding-top: 2em;
        padding-bottom: 2em;


        @include mq(sm) {
            padding: 2em;
        }

        @include mq(md) {
            padding: 3em;
        }

        .typography {
            max-width: 600px;
            margin-bottom: 0;
            color: color(greys, dark);
        }
    }

    .banner-side-by-side__cover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 0;

        & > img,
        picture > img {
            object-fit: cover;
            display: block;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
        }

        // For IE9, IE10 and IE11
        objectfit {
            display: block;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;

            img {
                opacity: 0;
            }
        }
    }
}

.section__banner-side-by-side--reversed {
    @extend .section__banner-side-by-side;

    @include mq(md) {
        [gr-grid=row] {
            [gr-grid] + [gr-grid] {
                order: -1;
            }
        }
    }
}
