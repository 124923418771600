// ==========================================================================
// Category
// ==========================================================================

.category {
    display: block;
    margin-bottom: $root-vgrid / 2;
    margin-top: $root-vgrid / 2;

    @include mq(lg) {
        transition: $transition-fast ease-out;
        &:hover {
            img {
                transform: scale3d(0.9, 0.9, 0.9);
            }

            h3:after {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    img {
        width: 90%;
        margin: 0 auto;
        @include mq(lg) {
            -webkit-backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: $transition-fast ease-out;
        }
    }
    h3 {
        text-transform: uppercase;
        color: color(greys, dark);
        margin-bottom: $root-vgrid / 2;
        display: inline-block;
        position: relative;
        @include mq(lg) {
            &:after {
                transition: $transition-fast ease-out;
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 100%;
                height: 4px;
                opacity: 0;
                transform: translate3d(0, $root-vgrid / 4, 0);
                background-color: color('brand', 'primary');
            }
        }
    }
}
