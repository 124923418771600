// ==========================================================================
// Team member
// ==========================================================================

.section-members {
    position: relative;
    text-align: center;
    margin: 0 auto;
    overflow: auto;
    max-width: $grid-container-width;
}

.team-member-slider {
    display: flex;
    margin: 0 auto;
    position: relative;
}

.team-member {
    width: 100%;
    padding-bottom: $root-vgrid / 2;
    position: relative;
    text-align: center;
}

.team-member__picture {
    display: block;
    width: 100%;
}

.team-member__name {
    border-top: 8px solid $color-primary;
    padding-top: ($root-vgrid / 2);
    padding-bottom: ($root-vgrid / 4);
    color: color('brand', 'primary');
    font-weight: 700;
    font-size: em(20, 16);
}

.team-member__title {
    font-size: em(16, 16);
}

.team-member__since {
    font-size: em(16, 16);
    display: block;
    padding-bottom: ($root-vgrid / 2);
    color: color('greys', 'dark');
    font-weight: 400;
}
