// ==========================================================================
// Mixin Media-query
// ==========================================================================

@mixin mq($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {  // stylelint-disable-line
            @content;
        }
    }
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$grid-breakpoints` map.';
    }
}
