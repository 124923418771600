.background {
    min-height: 30vh;
    position: relative;

    @include mq(md) {
        min-height: 50vh;
    }

    //Bouh :-(
    /*@include mq(md) {
        &:hover .background__header-cover:after {
            opacity: 0.6;
        }
    }*/
}

.background--home {
    min-height: 100vh;
    position: relative;

    @include mq(md) {
        min-height: 85vh;
        //Bouh :-(
        /*&:hover .background__header-cover:after {
            opacity: 0.6;
        }*/
    }

    @include mq(lg) {
        min-height: 90vh;
    }

    .background__header-cover {
        img {
            position: fixed;
        }
    }

}

.background__header-cover {
    @extend %banner-cover;
    background-color: color('brand','quinary');

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $darth-vader;
        opacity: 0.5;
        z-index: 0;
        @include mq(sm) {
            opacity: 0.3;
        }
    }
}

.background__header-top-cover {
    img {
        opacity: 0;
        transition: $transition-fast ease;
    }

    &.is-loaded {
        img {
            opacity: 1;
        }
    }
}

.background__header-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    vertical-align: middle;
    align-items: center;
    height: 100%;
    width: 100%;
    color: $snow-white;

    [gr-grid=container] {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    h1 {
        max-width: 100%;
        font-family: $font-secondary;
        font-size: em(24,16);
        font-weight: 400;
        margin-bottom: 0;
        margin-top: $root-vgrid;
        @include mq(md) {
            font-size: em(36,16);
            margin-top: $root-vgrid * 2;
            max-width: 70%;
        }

        @include mq(lg) {
            margin-top: $root-vgrid;
            max-width: 50%;
        }
    }

    p {
        max-width: 100%;
        font-size: em(14,16);
        margin-top: $root-vgrid;
        margin-bottom: $root-vgrid;
        @include mq(md) {
            max-width: 70%;
        }
        @include mq(lg) {
            max-width: 50%;
        }
    }
}

.background__breadcrumb {
    position: relative;
    padding-top: $root-vgrid / 2;
    padding-bottom: $root-vgrid / 4;
}

.background__breadcrumb-cover {
    @extend %banner-cover;
}

.background__header-cover--become-customer {
    img {
        object-position: 70%;
    }
}

.background__header-cover--why-us {
    img {
        object-position: 42%;
        @include mq(sm) {
            object-position: 50%;
        }
    }
}

.background__header-cover--our-engagement {
    img {
        object-position: 77%;
        @include mq(sm) {
            object-position: 50%;
        }
    }
}

.background__header-cover--become-supplier {
    img {
        object-position: 44%;
        @include mq(md) {
            object-position: 50%;
        }
    }
}
