// ==========================================================================
// Pagination
// ==========================================================================

.pagination {
    width: 100%;
    text-align: center;
    margin-top: $root-vgrid;
}

%pagination__link {
    display: inline-block;
    position: relative;
    font-weight: 700;
    font-size: em(14, 16);
    margin-right: $grid-gutter-width / 8;
    margin-left: $grid-gutter-width / 8;
    margin-bottom: $root-vgrid / 2;
    padding-right: $grid-gutter-width / 4;
    padding-left: $grid-gutter-width / 4;
    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 6px;
        opacity: 0;
        background-color: color('brand', 'primary');
    }
    @include mq(lg) {
        transition: $transition-fast ease-out;
        &:after {
            transition: $transition-fast ease-out;
        }
        &:hover {
            color: color('brand', 'primary');
            transform: scale3d(1.5, 1.5, 1.5) translate3d(0, -2px, 0);
            &:after {
                opacity: 1;
                transform: scale3d(.5, .5, .5);
            }
        }
    }
}

.pagination__link {
    @extend %pagination__link;
    color: color('greys', 'dark');
}

.pagination__link--current {
    @extend %pagination__link;
    transform: scale3d(1.5, 1.5, 1.5) translate3d(0, -2px, 0);
    color: color('brand', 'primary');

    &:after {
        opacity: 1;
        transform: scale3d(.5, .5, .5);
    }
}
