// ==========================================================================
// Filters
// ==========================================================================

.filters-list {
    list-style-type: none;
    li {
        display: inline-block;
        margin-right: $grid-gutter-width / 4;
        margin-bottom: $grid-gutter-width / 4;
        a {
            position: relative;
            display: inline-block;
            text-align: center;
            text-transform: uppercase;
            font-size: em(14, 16);
            font-weight: 700;
            padding: ($root-vgrid / 2) ($grid-gutter-width / 2);
            color: $snow-white;
            background-color: color(brand,secondary);
            @include mq(lg) {
                transition: $transition-fast ease-out;
                &:hover {
                    background-color: darken($color-secondary, 10%);
                }
            }
        }
        i {
            margin-left: $grid-gutter-width / 4
        }
    }
}

.filters-wrapper {
    padding-top: $root-vgrid * 2;
    .checkbox-wrapper label {
        padding-left: $grid-gutter-width;
    }
}

.filters-wrapper__title {
    text-transform: uppercase;
    color: color('brand','primary');
}

.filters-wrapper__container {
    display: none;
    @include mq(sm) {
        display: block;
    }
    &.is-open {
        display: block;
    }
}
