
.become-client__cover {
    @extend %banner-cover;
}

.background__become-client {
    color: $color-white;
    position: relative
}

.section__become-client {
    padding: $root-vgrid/2 0;

    .section-title {
        text-align: center;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $root-vgrid / 2;
    }

    [gr-grid=block] {
        text-align: center;

        .become-client__title {
            text-transform: uppercase;
            color: color(brand, secondary);
            margin-bottom: $root-vgrid / 2;
        }

        .typography {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;

            p {
                font-size: em(14px);
                line-height: 1.5;
                color: white;
            }
        }
    }

    .button-container {
        margin-top: $root-vgrid*2;
        margin-bottom: $root-vgrid;
    }
}
