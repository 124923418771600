// ==========================================================================
// Notice
// ==========================================================================

.notice {
    margin-top: $root-vgrid;
    background-color: color('greys','xlight');
    padding: $root-vgrid $grid-gutter-width $root-vgrid $grid-gutter-width * 3;
    position: relative;
}

.notice__icon {
    position: absolute;
    top: $root-vgrid;
    left: $grid-gutter-width;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    background-color: color('brand','primary');
    color: $snow-white;
}

.notice--white {
    margin-top: 0;
    background-color: $snow-white;

    .notice__icon {
        left: 0;
        @include mq(sm) {
            left: $grid-gutter-width / 2;
        }
    }

    .h3 {
        margin-top: 0;
        color: color('brand', 'primary');
        margin-bottom: $root-vgrid / 2;
    }

    p {
        color: color('greys', 'dark');
    }
}
